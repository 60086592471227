import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src268204938/src/personal-website/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeedSearch = makeShortcode("FeedSearch");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "posts-and-articles"
    }}>{`Posts and Articles`}</h1>
    <FeedSearch mdxType="FeedSearch" />
    <FeedItems filterByTags={['post', 'article']} mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      